/**
  Todo: Application width seems to be off by 1-2px
 */

/**
  AG Grid Styling
  This is required for the grids.
  https://ag-grid.com/angular-data-grid/getting-started/
 */
//@import 'ag-grid-community/styles/ag-grid.css';
//@import 'ag-grid-community/styles/ag-theme-quartz.css';

//@use 'ag-grid-community/styles' as ag;
//@include ag.grid-styles(());

/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/**
  AG Grid Styling
  This is required for the grids.
  https://ag-grid.com/angular-data-grid/getting-started/
 */
//@import 'ag-grid-community/styles/ag-grid.css';
//@import 'ag-grid-community/styles/ag-theme-quartz.css';

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import 'theme/ag-grid-theme.css';

@import './theme/animations/circle-dissapear-and-reappear';

/**
Refer to the [Article](https://techodactyl.youtrack.cloud/articles/G-A-15/Scrollable-Content-Sticky-Header-and-or-Footer)
for usage instructions and examples.
 */
ion-content.special-no-scroll {
  &::part(scroll) {
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .special-scroller {
    flex: 1 1 auto;
    overflow-y: auto;
    width: 100%;
  }

  .special-embedded-comp-wrapper { // For use where an embedded component has scrollable content.
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;

    > * { // make embedded content (child components) full height.
      height: 100%;
    }

    .special-child-component-root { // is placed in the most root tag of the child.
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
}

.text-select-no {
  user-select: none;
}

.text-select-contain {
  user-select: contain;
}

.orange-msg {
  > p {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border: solid 2px wheat;
    color: black;
    background: lightyellow;
    border-radius: 10px;
    margin: 10px;
    padding: 10px;

    > span.header {
      font-size: 1.2em;
      font-weight: bold;
      padding-bottom: 5px;
    }

    > span.sub-header {
      font-weight: bold;
      padding-bottom: 2px;
    }
  }
}

ion-button {
  text-transform: none;
}

.UPDATE-MESSAGE {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  > p {
    font-size: 0.8em;
    border-radius: 10px;
    background: rgba(144, 238, 144, 0.2);
    padding: 10px;

    > i:last-child {
      padding-top: 2ch;
      float: right;
    }
  }
}

.special-message {
  --backdrop-opacity: 0.5;
  --max-width: 90%;

  .modal-wrapper {
    border-radius: 10px;
  }
}

button[mat-button] {
  font-size: 1em;

  .mat-button-wrapper {
    font-size: 1em;
    display: flex;
    justify-content: center;
    align-items: center;

    ion-icon {
      --ionicon-stroke-weight: 64px;
      font-weight: bold;

      &.left {
        padding-right: 0.5ch;
      }

      &.right {
        padding-left: 0.5ch;
      }
    }
  }

  &.space-between .mat-button-wrapper {
    justify-content: space-between;
  }
}


.table-scroll-container {
  flex: 1;
  overflow-y: auto;
  width: 100%;

  table {
    border-collapse: unset;
    width: 100%;

    thead {
      position: sticky;
      top: 0;
      z-index: 1;
      background: var(--ion-color-white);
    }
  }
}

table.fixed-head {
  border-collapse: unset;

  thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background: white;
  }
}


.scroll-pain-wrapper-wrapper {
  display: flex;
  height: 100%;
  width: 100%;
}

.scroll-pain-wrapper {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
}

.scroll-pain {
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  overflow: auto;
  min-height: 0;
  height: 100%;
  width: 100%;
}

ion-icon:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.centered-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80%;
}

[draggable=true] {
  cursor: grab;
}

#store-tabs, .tabs {
  background: var(--ion-color-light);
  padding-bottom: 0;
  border-bottom: solid 1px lightgray;

  > ion-row > ion-col {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--ion-color-white);
    color: lightgray;
    border: solid 1px lightgray;
    border-radius: 10px 10px 0 0;
    margin-bottom: -1px;
    cursor: pointer;

    &.selected {
      border-bottom-color: white;
      color: var(--ion-color-primary);
    }

    &[click] {
      cursor: pointer;

      &:hover {
        color: var(--ion-color-primary-tint);
      }
    }

    .text-holder {
      // TODO: Fill the margin (remove the ion-col padding) and put this class in all implementations of the tabs
      flex: 1 0 auto;
      cursor: pointer;

      &:hover {
        color: var(--ion-color-primary-tint);
      }
    }

    > app-store-live-indicator {
      font-size: 0.5em;
    }
  }

  .badged-button ion-badge {
    top: -0.6em;
    font-size: 0.6em;
  }

  .badged-button.selected ion-badge {
    background: rgba(211, 211, 211, 0.8);
  }
}

.inline-toggle {
  display: flex;
  align-items: center;

  > span.right {
    padding-left: 1ch;
    margin-left: auto;
  }

  ion-toggle {
    --padding-left: 5px;
    --padding-right: 5px;
    --padding-top: 5px;
    --padding-bottum: 5px;
  }
}

//#store-tabs {
//  background: #fafafa;
//  padding-bottom: 0;
//  ion-row {
//    ion-col {
//      background: #fdfdfd;
//      border-radius: 10px 10px 0 0;
//      margin-right: 5px;
//      text-align: center;
//    }
//    ion-col:hover {
//      background: lightyellow;right
//      color: var(--ion-color-primary);
//      cursor: pointer;
//    }
//    ion-col.selected {
//      background: white;
//      color: var(--ion-color-primary);
//    }
//  }
//  .badged-button ion-badge {
//    top: -0.6em;
//    font-size: 0.6em;
//  }
//  .badged-button.selected ion-badge {
//    background: rgba(211, 211, 211, 0.8);
//  }
//}
ion-skeleton-text {
  height: 2ex;
}

.mat-tooltip {
  white-space: pre-line; // use? &#13;

  &.form-tip {
    margin-top: 5px;
    font-size: 0.8em;
  }

  &.invalid-tip {
    background: rgba(var(--ion-color-danger-rgb), 0.6);
    color: var(--ion-color-danger-contrast);
  }
}

button[mat-raised-button] {
  background: var(--ion-color-white-tint);
  color: var(--ion-color-white-contrast);
}

.custom-alert {
  --backdrop-opacity: 0.2;
  --max-width: 90%;

  .alert-wrapper {
    border-radius: 10px;
  }

  .alert-button-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    button {
      text-transform: none;
    }

    button.warn {
      color: var(--ion-color-warning);
    }

    button.dang {
      color: var(--ion-color-danger);
    }
  }

  &.warn {
    .alert-wrapper {
      box-shadow: 2px 2px 2px 4px var(--ion-color-warning);
    }
  }

  &.error {
    .alert-wrapper {
      box-shadow: 2px 2px 2px 4px var(--ion-color-danger);
    }
  }
}

// TODO: FOK
//ion-modal::part(content) app-progress.ion-page {
//  border-radius: 10px;
//  --max-height: 10%;
//  --max-width: 10%;
//}

.modal-default {
  z-index: 10 !important;
}

ion-fab.pulse-notification-fab {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--ion-color-warning);
  color: var(--ion-color-warning-contrast);
  font-size: 2em;
  padding: 1.5ch;
  border-radius: 50%;
  width: 1ch;
  height: 1ch;
  animation: pulse-notification-fab-ani 2s infinite;
  @keyframes pulse-notification-fab-ani {
    0% {
      opacity: 100%;
    }
    //25% { opacity: 60%; }
    50% {
      opacity: 30%;
    }
    //75% { opacity: 60%; }
    //100% { opacity: 10%; }
  }
}

ion-accordion {
  &.accordion-collapsing, &.accordion-collapsed {
    ion-item {
      .show-on-collapsed {
        visibility: visible;
      }

      .show-on-expand {
        width: 0;
        height: 0;
        visibility: hidden;
      }
    }
  }

  &.accordion-expanding, &.accordion-expanded {
    ion-item {
      .show-on-collapsed {
        visibility: hidden;
        width: 0;
        height: 0;
      }

      .show-on-expand {
        visibility: visible;
      }
    }
  }
}

.ao-ready-preview-modal {
  z-index: 10 !important;
  --width: 1100px;
  --height: 500px;
}

.larger-modal {
  z-index: 10 !important;
  @media only screen and (min-width: 1780px) {
    --width: 1060px;
  }
  @media only screen and (min-width: 1080px) and (max-width: 1780px) {
    --width: 60%;
  }
  @media only screen and (min-width: 767px) and (max-width: 1079px) {
    --width: 80%;
  }
}

.child-modal {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  z-index: 10 !important;
}

.card-modal {
  z-index: 10 !important;
  --width: 450px;
  --height: 400px;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
}

.larger-card-modal {
  --width: 480px;
  --height: 550px;
  z-index: 10 !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
}

.even-larger-card-modal {
  --width: 480px;
  --height: 600px;
  z-index: 10 !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
}

.largest-card-modal {
  --width: 480px;
  --height: 650px;
  z-index: 10 !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
}

.happy-toast {
  --background: rgba(0, 188, 212, 0.45);
  --color: black;
  font-size: 1em;
  font-weight: bold;
}

.custom-pop {
  --max-width: 80%;
}

.select-recipients {
  // select-popover
  --width: 600px !important;
}

//ion-modal::part(content) {
//  app-select-popover {
//    border: solid 5px red;
//  }
//}

.select-pop-as-modal {
  z-index: 10 !important;
  --width: 250px;
  --max-height: 90%;
}

.refresh-modal {
  z-index: 20000 !important;
  --width: 400px;
  --height: 400px;
  backdrop-filter: blur(4px);
}

.shared-basic-modal-css {
  z-index: 20000 !important;
  //--width: 400px;
  --height: 400px;
  backdrop-filter: blur(4px);

  ion-content {
    height: 280px;
    max-height: 280px;
  }
}

.shared-basic-modal-css-small {
  z-index: 20000 !important;
  --height: 280px;
  --overflow: hidden;
  overflow: hidden;
  backdrop-filter: blur(4px);

  ion-content {
    height: 150px;
    max-height: 150px;
    overflow: hidden;
  }
}


.shared-basic-modal-css-medium {
  z-index: 20000 !important;
  --height: 300px;
  --overflow: hidden;
  overflow: hidden;
  backdrop-filter: blur(4px);

  ion-content {
    height: 170px;
    max-height: 170px;
    overflow: hidden;
  }
}

.auto-ordering-filter-modal {
  z-index: 20000 !important;
  --height: 265px;
  --width: 650px;
  backdrop-filter: blur(4px);
}

.shared-basic-modal-css-smaller {
  z-index: 20000 !important;
  --height: 200px;
  backdrop-filter: blur(4px);

  ion-content {
    height: 110px;
    max-height: 110px;
  }
}

.shared-basic-modal-css-larger {
  z-index: 20000 !important;
  --width: 800px;
  --height: 520px;
  backdrop-filter: blur(4px);

  ion-content {
    height: 400px;
    max-height: 400px;
  }
}

.notification-modal-css {
  z-index: 20000 !important;
  --width: 950px;
  --height: 665px;
  --border-radius: 0;
  backdrop-filter: blur(4px);

  ion-content {
    height: 400px;
    max-height: 400px;
  }
}

.shared-modal-filters-advanced {
  z-index: 20000 !important;
  --width: 1000px;
  --height: 600px;
  backdrop-filter: blur(4px);
}

.input-modal-small {
  z-index: 10 !important;
  --height: 35%;
  margin: auto;

  &:host {
    div {
      .modal-wrapper {
        height: 100%;
      }
    }
  }

}

.sexy-modal-main-buttons {
  display: flex;
  justify-content: space-evenly;
  padding: 10px;

  ion-button, button {
    font-size: 1em;
    color: var(--ion-color-primary) !important;
    width: 28ch;
  }

  button[disabled] {
    color: grey;
  }
}

.change-popover {
  --max-width: 95%;

  .displayed {
    padding: 10px;
    display: flex;
    justify-content: space-between;
  }
}

.badged-button {
  background: inherit;

  ion-icon {
    color: var(--ion-color-primary);
    font-size: 2em;
  }

  ion-badge {
    font-size: 0.8em;
    position: relative;
    top: -1.3em;
    left: -0.5em;
    background: rgba(235, 68, 90, 0.8);
  }
}

.badged-button.no-change {
  cursor: inherit;

  ion-icon {
    color: grey !important;
  }

  ion-badge {
    visibility: hidden;
  }
}

.badged-button:hover {
  ion-icon {
    //animation: ;
  }
}

.shrinkable {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .shrink-part {
    visibility: hidden;
    width: 0;
    transition: width 1s, visibility 1s;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: fade;
    cursor: default;
  }

  &:hover .shrink-part {
    visibility: visible;
    width: 20ch;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: fade;
  }
}

ion-input[type='search'].search-bar {
  border: solid 1px lightgray;
  border-radius: 5px;

  > ion-icon[name='search-circle-outline'] {
    margin-left: 8px;
    font-size: 2em;
    color: #7e7e7e;
    --ionicon-stroke-width: 24px;
    cursor: pointer;
  }
}

.search-highlight-emphasis {
  color: orangered;

  .exact {
    color: yellowgreen;
  }
}

.menu-popup {
  --backdrop-opacity: 0 !important;
  //--height: 250px;
}

//.ais-Pagination-list {
//  list-style-type: none !important;
//  display: flex;
//  justify-content: center;
//  padding: 0;
//  li {
//    padding: 3px;
//    a {
//      text-decoration: none;
//      color: var(--ion-color-primary);
//      border: solid 1px white;
//      border-radius: 10px;
//      padding: 2px;
//    }
//  }
//
//  li.ais-Pagination-item--selected {
//    a { border-color: var(--ion-color-primary); }
//  }
//}
//
//.ais-SearchBox-form {
//  display: flex;
//  justify-content: flex-start;
//  align-items: center;
//}
////.ais-SearchBox-preview > svg path {
////  fill: rgb(255 , 204, 0) !important;
////  d: path('M80 212v236a16 16 0 0016 16h96V328a24 24 0 0124-24h80a24 24 0 0124 24v136h96a16 16 0 0016-16V212') !important;
////  stroke-width: 0;
////}
//.ais-SearchBox-submit, .ais-SearchBox-reset {
//  display: flex;
//  justify-content: center;
//  align-items: center;
//  background: transparent;
//  border: solid 1px black;
//  border-radius: 50%;
//  padding: 5px;
//  margin-left: 5px;
//  svg {
//    width: 1em;
//    height: 1em;
//  }
//}
//.ais-SearchBox-submit:hover {
//  background: var(--ion-color-primary);
//  svg path { fill: var(--ion-color-primary-contrast); }
//}
//.ais-SearchBox-reset:hover {
//  background: var(--ion-color-warning);
//  svg path { fill: var(--ion-color-warning-contrast); }
//}
//.ais-ClearRefinements-button {
//  border: solid 1px black;
//  border-radius: 10px;
//  background: inherit;
//}
//.ais-ClearRefinements-button:hover {
//  background: var(--ion-color-primary);
//  color: var(--ion-color-primary-contrast);
//  cursor: pointer;
//}
//.ais-RefinementList-item {
//  list-style-type: none !important;
//  .ais-RefinementList-label {
//    display: flex;
//    flex-direction: row;
//    justify-content: flex-start;
//    align-items: center;
//    .ais-RefinementList-labelText {
//      padding-left: 5px;
//    }
//    .ais-RefinementList-count {
//      margin-left: 5px;
//      display: flex;
//      align-items: center;
//      align-self: center;
//      font-size: 0.8em;
//      background: #f7f7f8;
//      border-radius: 10px;
//      padding: 2px 5px;
//      text-align: center;
//      vertical-align: middle;
//    }
//  }
//}
//.ais-RefinementList-item--selected {
//  input {}
//}

// ---------------------------------------------------- ANIMATION --------------------------------------------------- //

//@media (prefers-color-scheme: dark) {
button[mat-button] {
  background: var(--ion-color-light);
  color: var(--ion-color-light-contrast) !important;
  --box-shadow: 0 0.5rem 1rem rgba(214, 66, 86, 0.98);

  &[disabled] {
    color: var(--ion-color-light-tint) !important;
  }
}

//}

// ------------------------------------------------- COLOR CLASSES -------------------------------------------------- //

.selectable-list {
  .selectable-list-item {
    cursor: pointer;

    &:hover {
      background: lightcyan !important;
      color: black !important
    }

    &.selected {
      background: #ffff9f !important;

      &:hover {
        background: lightgray !important;
      }
    }

    &.disabled {
      cursor: none;
      color: grey;
    }
  }
}


.pastel-9 {
  &.background {
    &.c8 {
      background: #EAE4E9 !important;
    }

    &.c7 {
      background: #FFF1E6 !important;
    }

    &.c6 {
      background: #FDE2E4 !important;
    }

    &.c5 {
      background: #FAD2E1 !important;
    }

    &.c4 {
      background: #E2ECE9 !important;
    }

    &.c3 {
      background: #BEE1E6 !important;
    }

    &.c2 {
      background: #F0EFEB !important;
    }

    &.c1 {
      background: #DFE7FD !important;
    }

    &.c0 {
      background: #CDDAFD !important;
    }
  }

  &.color {
    &.c8 {
      color: #EAE4E9 !important;
    }

    &.c7 {
      color: #FFF1E6 !important;
    }

    &.c6 {
      color: #FDE2E4 !important;
    }

    &.c5 {
      color: #FAD2E1 !important;
    }

    &.c4 {
      color: #E2ECE9 !important;
    }

    &.c3 {
      color: #BEE1E6 !important;
    }

    &.c2 {
      color: #F0EFEB !important;
    }

    &.c1 {
      color: #DFE7FD !important;
    }

    &.c0 {
      color: #CDDAFD !important;
    }
  }

  &.border {
    &.c8 {
      border-color: #EAE4E9 !important;
    }

    &.c7 {
      border-color: #FFF1E6 !important;
    }

    &.c6 {
      border-color: #FDE2E4 !important;
    }

    &.c5 {
      border-color: #FAD2E1 !important;
    }

    &.c4 {
      border-color: #E2ECE9 !important;
    }

    &.c3 {
      border-color: #BEE1E6 !important;
    }

    &.c2 {
      border-color: #F0EFEB !important;
    }

    &.c1 {
      border-color: #DFE7FD !important;
    }

    &.c0 {
      border-color: #CDDAFD !important;
    }
  }
}

.cell-changed {
  background: rgb(var(--ion-color-light-rgb), 0.75) !important;
  color: var(--ion-color-success-v1) !important;
}

.add-user-modal {
  --background: var(--ion-color-light-v1);
  z-index: 20000 !important;
  --max-height: 580px;
  --width: 500px;
  --border-radius: 15px;
  --border: 2px solid var(--ion-color-primary-v1);
  backdrop-filter: blur(4px);
}

.cell-disabled {
  border-color: transparent !important;
}

.cdk-overlay-container {
  z-index: 99999999 !important;
}


app-shared-stock-search {
  height: 100%;
  display: block;
}
