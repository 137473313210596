// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat.define-palette(mat.$indigo-palette);
$app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$app-theme: mat.define-light-theme((
  color: (
    primary: $app-primary,
    accent: $app-accent,
    warn: $app-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);


//// Custom Theming for Angular Material
//// For more information: https://material.angular.io/guide/theming
//@use '~@angular/material' as mat;
//// Plus imports for other components in your app.
//
//// Include the common styles for Angular Material. We include this here so that you only
//// have to load a single css file for Angular Material in your app.
//// Be sure that you only ever include this mixin once!
//@include mat.core();
//
//// Define the palettes for your theme using the Material Design palettes available in palette.scss
//// (imported above). For each palette, you can optionally specify a default, lighter, and darker
//// hue. Available color palettes: https://material.io/design/color/
//$app-primary: mat.define-palette(mat.$indigo-palette);
//$app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
//
//// The warn palette is optional (defaults to red).
//$app-warn: mat.define-palette(mat.$red-palette);
//
//// Create the theme object. A theme consists of configurations for individual
//// theming systems such as "color" or "typography".
//$app-theme: mat.define-light-theme((
//  color: (
//    primary: $app-primary,
//    accent: $app-accent,
//    warn: $app-warn,
//  )
//));
//
//// Include theme styles for core and each component used in your app.
//// Alternatively, you can import and @include the theme mixins for each component
//// that you are using.
//@include mat.all-component-themes($app-theme);
//
//// Ionic Variables and Theming. For more info, please see:
//// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** Gallix Logo **/
  --gallix-logo-black: black;
  --gallix-logo-blue: #26C6BC;

  /** primary **/
  --ion-color-primary: var(--gallix-logo-blue);
  //--ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;
  --ion-color-warning-light: rgba(255, 240, 0, 0.91);

  /** danger **/
  --ion-color-danger: rgb(235, 68, 90);
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** white **/
  --ion-color-white: #fff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-contrast-rgba: 0, 0, 0, 0.1;
  --ion-color-white-shade: #d7d8da;
  --ion-color-white-tint: #f5f6f9;

  /** grey **/
  --ion-color-grey: #808080;
  --ion-color-grey-rgb: 128, 128, 128;
  --ion-color-grey-contrast: #ffffff;
  --ion-color-grey-contrast-rgb: 255, 255, 255;
  --ion-color-grey-shade: #6e6e6e;
  --ion-color-grey-tint: #9c9c9c;


  /** V1 Colors Light Mode**/

  /** primary V1 **/
  --ion-color-primary-v1: #004455;
  --ion-color-primary-rgb-v1: 0, 68, 85;
  --ion-color-primary-contrast-v1: #ffffff;
  --ion-color-primary-contrast-rgb-v1: 255, 255, 255;
  --ion-color-primary-shade-v1: #003a46;
  --ion-color-primary-tint-v1: #005068;
  --ion-color-primary-tint-gradient-v1: linear-gradient(234deg, rgba(34, 193, 195, 1) 0%, rgba(24, 156, 162, 1) 18%, rgba(0, 80, 104, 1) 41%, rgba(21, 101, 108, 1) 77%, rgba(32, 94, 97, 1) 100%);


  /** secondary V1 **/
  --ion-color-secondary-v1: #26c6bc;
  --ion-color-secondary-rgb-v1: 38, 198, 188;
  --ion-color-secondary-contrast-v1: #ffffff;
  --ion-color-secondary-contrast-rgb-v1: 0, 0, 0;
  --ion-color-secondary-shade-v1: #1f928c;
  --ion-color-secondary-tint-v1: #31d6c8;
  --ion-color-secondary-hover-v1: linear-gradient(to right, #347989, #296675) !important;

  /** tertiary V1 **/
  --ion-color-tertiary-v1: #5755f6;
  --ion-color-tertiary-rgb-v1: 87, 85, 246;
  --ion-color-tertiary-contrast-v1: #ffffff;
  --ion-color-tertiary-contrast-rgb-v1: 255, 255, 255;
  --ion-color-tertiary-shade-v1: #4c4ac8;
  --ion-color-tertiary-tint-v1: #6c6af8;

  /** success V1 **/
  --ion-color-success-v1: #6dc55c;
  --ion-color-success-rgb-v1: 109, 197, 92;
  --ion-color-success-contrast-v1: #ffffff;
  --ion-color-success-contrast-rgb-v1: 255, 255, 255;
  --ion-color-success-shade-v1: #5ba24b;
  --ion-color-success-tint-v1: #85d77b;

  /** warning V1 **/
  --ion-color-warning-v1: #ffb368;
  --ion-color-warning-rgb-v1: 255, 179, 104;
  --ion-color-warning-contrast-v1: #000000;
  --ion-color-warning-contrast-rgb-v1: 0, 0, 0;
  --ion-color-warning-shade-v1: #e09c58;
  --ion-color-warning-tint-v1: #ffbd80;
  --ion-color-warning-light-v1: rgba(255, 179, 104, 0.91);

  /** danger V1 **/
  --ion-color-danger-v1: rgb(235, 68, 90);
  --ion-color-danger-rgb-v1: 235, 68, 90;
  --ion-color-danger-contrast-v1: #ffffff;
  --ion-color-danger-contrast-rgb-v1: 255, 255, 255;
  --ion-color-danger-shade-v1: #cf3c4f;
  --ion-color-danger-tint-v1: #ed576b;

  /** dark V1**/
  --ion-color-dark-v1: #25282f;
  --ion-color-dark-rgb-v1: 37, 40, 47;
  --ion-color-dark-contrast-v1: #ffffff;
  --ion-color-dark-contrast-rgb-v1: 255, 255, 255;
  --ion-color-dark-shade-v1: #21242b;
  --ion-color-dark-tint-v1: #3b3e3f;

  /** medium V1 **/
  --ion-color-medium-v1: #9598a3;
  --ion-color-medium-rgb-v1: 149, 152, 163;
  --ion-color-medium-contrast-v1: #ffffff;
  --ion-color-medium-contrast-rgb-v1: 255, 255, 255;
  --ion-color-medium-shade-v1: #838491;
  --ion-color-medium-tint-v1: #a0a2ab;

  /** light V1 **/
  --ion-color-light-v1: #f7f9ff;
  --ion-color-light-rgb-v1: 247, 249, 255;
  --ion-color-light-contrast-v1: #000000;
  --ion-color-light-contrast-rgb-v1: 0, 0, 0;
  --ion-color-light-shade-v1: #dadce2;
  --ion-color-light-tint-v1: #f8faff;

  /** white V1 **/
  --ion-color-white-v1: #fff;
  --ion-color-white-rgb-v1: 255, 255, 255;
  --ion-color-white-contrast-v1: #000000;
  --ion-color-white-contrast-rgb-v1: 0, 0, 0;
  --ion-color-white-shade-v1: #d7d8da;
  --ion-color-white-tint-v1: #f5f6f9;

  /** Constants **/
  --ion-color-constant-white: #ffffff;
  --ion-color-constant-black: #000000;

  /** Gradients **/
  --ion-color-gradient-hover: rgba(58, 129, 141, 0.9);
  --ion-color-gradient-selected: rgba(76, 161, 175, 0.7);

  /** Owner Colours **/
  --owner-color-0: var(--ion-color-primary-v1);
  --owner-color-1: var(--ion-color-secondary-v1);
  --owner-color-2: var(--ion-color-tertiary-v1);
  --owner-color-3: var(--ion-color-success-v1);
  --owner-color-4: var(--ion-color-warning-v1);
  --owner-color-5: var(--ion-color-danger-v1);
  --owner-color-6: var(--ion-color-primary-tint-v1);
  --owner-color-7: var(--ion-color-secondary-tint-v1);
  --owner-color-8: var(--ion-color-tertiary-tint-v1);
  --owner-color-9: var(--ion-color-success-tint-v1);
  --ion-card-background: var(--ion-color-light-v1);
  --g-animation-bckd-order-list: #fff;

  /** CUSTOM COLOUR VARIABLES -------------------------------------------------------------------- **/

  /** Updated Colours **/
  --form-background-color: #ffffff;

  /** GLOBAL COLOURS **/
  --global-error: var(--ion-color-danger);
  --global-error-text: var(--ion-color-light-v1);
  --global-success: var(--ion-color-success);
  --global-success-text: var(--ion-color-light-v1);
  --global-warning: var(--ion-color-warning);
  --global-warning-text: var(--ion-color-light-v1);


  /** SHARED ACCORDION COLOURS **/
  --shared-accordion-header-background: var(--ion-color-step-100);
  --shared-accordion-header-font: var(--ion-color-step-900);
  --shared-accordion-header-hover-background: var(--ion-color-step-100);
  --shared-accordion-header-hover-font: var(--ion-color-warning);
  --shared-accordion-body-background: var(--ion-color-step-300);
  --shared-accordion-body-font: var(--ion-color-step-100);
  --shared-accordion-notification-unread: var(--ion-color-primary-shade-v1);

  /** SHARED NOTIFICATION COLOURS **/
  --notification-animation-background: var(--ion-color-white);
  --notification-auto-order-animation-background: var(--ion-color-step-300);
  --notification-auto-order-dotted-lines: var(--ion-color-primary-shade-v1);
  --notification-stock-update-animation-background: var(--ion-color-step-300);
  --notification-stock-update-dotted-lines: var(--ion-color-primary-shade-v1);
}

/** --------------------------------------------------------------------------
                                    Custom
---------------------------------------------------------------------------**/
//--row-selectable-selected: lightyellow;
//--row-selectable-selected-odd: mix(lightyellow, #fafafa);
//--row-selectable-hover: lightcyan;
//--row-selectable-hover-odd: mix(lightcyan, #fafafa);
//--row-selectable-selected-hover: red;
//--row-selectable-selected-hover-odd: mix(red, #fafafa);
// ---------------------------------------------------------------------------


@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    /** Gallix Logo **/
    --gallix-logo-black: black;
    --gallix-logo-blue: #26C6BC;

    /** primary **/
    --ion-color-primary: var(--gallix-logo-blue);
    //--ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66, 140, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    /** secondary **/
    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80, 200, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    /** tertiary **/
    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106, 100, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    /** success **/
    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47, 223, 117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    /** warning **/
    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255, 213, 52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;
    --ion-color-warning-light: rgba(255, 240, 0, 0.91);

    /** danger **/
    --ion-color-danger: rgb(255, 73, 97);
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    /** dark **/
    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    /** medium **/
    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    /** light **/
    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;

    /** white **/
    --ion-color-white: #121212;
    --ion-color-white-rgb: 18, 18, 18;
    --ion-color-white-contrast: #fff;
    --ion-color-white-contrast-rgb: 255, 255, 255;
    --ion-color-white-contrast-rgba: 255, 255, 255, 0.1;
    --ion-color-white-shade: #000000;
    --ion-color-white-tint: #383838;

    /** grey **/
    --ion-color-grey: #808080;
    --ion-color-grey-rgb: 128, 128, 128;
    --ion-color-grey-contrast: #ffffff;
    --ion-color-grey-contrast-rgb: 255, 255, 255;
    --ion-color-grey-shade: #6e6e6e;
    --ion-color-grey-tint: #9c9c9c;

    /** V1 Colors Dark Mode **/

    /** primary V1 **/
    --ion-color-primary-v1: #0a5055;
    --ion-color-primary-rgb-v1: 10, 80, 85;
    --ion-color-primary-contrast-v1: #ffffff;
    --ion-color-primary-contrast-rgb-v1: 255, 255, 255;
    --ion-color-primary-shade-v1: #084246;
    --ion-color-primary-tint-v1: #0c6b75;
    --ion-color-primary-tint-gradient-v1: linear-gradient(234deg, rgba(34, 193, 195, 1) 0%, rgba(24, 156, 162, 1) 18%, rgba(12, 107, 117, 1) 41%, rgba(21, 101, 108, 1) 77%, rgba(32, 94, 97, 1) 100%);

    /** secondary V1 **/
    --ion-color-secondary-v1: #3bd1cc;
    --ion-color-secondary-rgb-v1: 59, 209, 204;
    --ion-color-secondary-contrast-v1: #ffffff;
    --ion-color-secondary-contrast-rgb-v1: 255, 255, 255;
    --ion-color-secondary-shade-v1: #28aaa5;
    --ion-color-secondary-tint-v1: #44e1db;

    /** tertiary V1 **/
    --ion-color-tertiary-v1: #6d67ff;
    --ion-color-tertiary-rgb-v1: 109, 103, 255;
    --ion-color-tertiary-contrast-v1: #ffffff;
    --ion-color-tertiary-contrast-rgb-v1: 255, 255, 255;
    --ion-color-tertiary-shade-v1: #5a52d4;
    --ion-color-tertiary-tint-v1: #8579ff;

    /** success V1 **/
    --ion-color-success-v1: #74e069;
    --ion-color-success-rgb-v1: 116, 224, 105;
    --ion-color-success-contrast-v1: #000000;
    --ion-color-success-contrast-rgb-v1: 0, 0, 0;
    --ion-color-success-shade-v1: #66b453;
    --ion-color-success-tint-v1: #8fe787;

    /** warning V1 **/
    --ion-color-warning-v1: #ffc587;
    --ion-color-warning-rgb-v1: 255, 197, 135;
    --ion-color-warning-contrast-v1: #000000;
    --ion-color-warning-contrast-rgb-v1: 0, 0, 0;
    --ion-color-warning-shade-v1: #e0ad6e;
    --ion-color-warning-tint-v1: #ffd7a3;
    --ion-color-warning-light-v1: rgba(255, 179, 104, 0.91);


    /** danger V1 **/
    --ion-color-danger-v1: rgb(255, 73, 97);
    --ion-color-danger-rgb-v1: 255, 73, 97;
    --ion-color-danger-contrast-v1: #ffffff;
    --ion-color-danger-contrast-rgb-v1: 255, 255, 255;
    --ion-color-danger-shade-v1: #e04055;
    --ion-color-danger-tint-v1: #ff5b71;

    /** dark V1 **/
    --ion-color-dark-v1: #f4f5f8;
    --ion-color-dark-rgb-v1: 244, 245, 248;
    --ion-color-dark-contrast-v1: #000000;
    --ion-color-dark-contrast-rgb-v1: 0, 0, 0;
    --ion-color-dark-shade-v1: #d7d8da;
    --ion-color-dark-tint-v1: #f5f6f9;

    /** medium V1 **/
    --ion-color-medium-v1: #9ea0a9;
    --ion-color-medium-rgb-v1: 158, 160, 169;
    --ion-color-medium-contrast-v1: #000000;
    --ion-color-medium-contrast-rgb-v1: 0, 0, 0;
    --ion-color-medium-shade-v1: #8a8b95;
    --ion-color-medium-tint-v1: #aaabb4;

    /** light V1 **/
    --ion-color-light-v1: #303337;
    --ion-color-light-rgb-v1: 48, 51, 55;
    --ion-color-light-contrast-v1: #ffffff;
    --ion-color-light-contrast-rgb-v1: 255, 255, 255;
    --ion-color-light-shade-v1: #2a2d30;
    --ion-color-light-tint-v1: #3a3d40;

    /** white V1 **/
    --ion-color-white-v1: #121212;
    --ion-color-white-rgb-v1: 18, 18, 18;
    --ion-color-white-contrast-v1: #fff;
    --ion-color-white-contrast-rgb-v1: 255, 255, 255;
    --ion-color-white-shade-v1: #000000;
    --ion-color-white-tint-v1: #383838;

    /** Constants **/
    --ion-color-constant-white: #ffffff;
    --ion-color-constant-black: #000000;

    /** Gradients **/
    --ion-color-gradient-hover: rgba(58, 129, 141, 0.9);
    --ion-color-gradient-selected: rgba(76, 161, 175, 0.7);

    /** Owner Colours **/
    --owner-color-0: var(--ion-color-primary-v1);
    --owner-color-1: var(--ion-color-secondary-v1);
    --owner-color-2: var(--ion-color-tertiary-v1);
    --owner-color-3: var(--ion-color-success-v1);
    --owner-color-4: var(--ion-color-warning-v1);
    --owner-color-5: var(--ion-color-danger-v1);
    --owner-color-6: var(--ion-color-primary-tint-v1);
    --owner-color-7: var(--ion-color-secondary-tint-v1);
    --owner-color-8: var(--ion-color-tertiary-tint-v1);
    --owner-color-9: var(--ion-color-success-tint-v1);
    --ion-card-background: #1e1e1e;
    --g-animation-bckd-order-list: #121212;

    /** CUSTOM COLOUR VARIABLES -------------------------------------------------------------------- **/

    /** Updated Colours **/
    --form-background-color: #1f1f1f;

    /** GLOBAL COLOURS **/
    --global-error: var(--ion-color-danger);
    --global-error-text: var(--ion-color-light-v1);
    --global-success: var(--ion-color-success);
    --global-success-text: var(--ion-color-light-v1);
    --global-warning: var(--ion-color-warning);
    --global-warning-text: var(--ion-color-light-v1);

    /** SHARED ACCORDION COLOURS **/
    --shared-accordion-header-background: var(--ion-color-step-200);
    --shared-accordion-header-font: var(--ion-color-step-900);
    --shared-accordion-header-hover-background: var(--ion-color-step-100);
    --shared-accordion-header-hover-font: var(--ion-color-warning);
    --shared-accordion-body-background: var(--ion-color-step-50);
    --shared-accordion-body-font: var(--ion-color-step-700);
    --shared-accordion-notification-unread: var(--ion-color-primary-shade-v1);

    /** SHARED NOTIFICATION COLOURS **/
    --notification-animation-background: var(--ion-color-white);
    --notification-auto-order-animation-background: var(--ion-color-step-50);
    --notification-auto-order-dotted-lines: var(--ion-color-primary-shade-v1);

    --notification-stock-update-animation-background: var(--ion-color-step-50);
    --notification-stock-update-dotted-lines: var(--ion-color-primary-shade-v1);
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18, 18, 18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;
  }
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* Importing Bootstrap SCSS file. */
@import '../../node_modules/bootstrap/scss/bootstrap';
