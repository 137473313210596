@keyframes circleDisappearAndReappear {
  0% {
    clip-path: polygon(50% 50%, 100% 50%, 100% 50%);
  }
  3.23% {
    clip-path: polygon(50% 50%, 100% 50%, 100% 75%);
  }
  6.45% {
    clip-path: polygon(50% 50%, 100% 50%, 100% 100%);
  }
  9.68% {
    clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 75% 100%);
  }
  12.9% {
    clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%);
  }
  16.13% {
    clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 25% 100%);
  }
  19.35% {
    clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 0% 100%);
  }
  22.58% {
    clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 0% 100%, 0% 75%);
  }
  25.81% {
    clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 0% 100%, 0% 50%);
  }
  29.03% {
    clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 0% 100%, 0% 25%);
  }
  32.26% {
    clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 0% 100%, 0% 0%);
  }
  35.48% {
    clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 0% 100%, 0% 0%, 25% 0%);
  }
  38.71% {
    clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 0% 100%, 0% 0%, 50% 0%);
  }
  41.94% {
    clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 0% 100%, 0% 0%, 75% 0%);
  }
  45.16% {
    clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 0% 100%, 0% 0%, 100% 0%);
  }
  48.39% {
    clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 0% 100%, 0% 0%, 100% 0%, 100% 25%);
  }
  51.61% {
    clip-path: polygon(50% 50%, 100% 75%, 100% 100%, 0% 100%, 0% 0%, 100% 0%, 100% 50%);
  }
  54.84% {
    clip-path: polygon(50% 50%, 100% 100%, 0% 100%, 0% 0%, 100% 0%, 100% 50%);
  }
  58.06% {
    clip-path: polygon(50% 50%, 75% 100%, 0% 100%, 0% 0%, 100% 0%, 100% 50%);
  }
  61.29% {
    clip-path: polygon(50% 50%, 50% 100%, 0% 100%, 0% 0%, 100% 0%, 100% 50%);
  }
  64.52% {
    clip-path: polygon(50% 50%, 25% 100%, 0% 100%, 0% 0%, 100% 0%, 100% 50%);
  }
  67.74% {
    clip-path: polygon(50% 50%, 0% 100%, 0% 0%, 100% 0%, 100% 50%);
  }
  70.97% {
    clip-path: polygon(50% 50%, 0% 75%, 0% 0%, 100% 0%, 100% 50%);
  }
  74.19% {
    clip-path: polygon(50% 50%, 0% 50%, 0% 0%, 100% 0%, 100% 50%);
  }
  77.42% {
    clip-path: polygon(50% 50%, 0% 25%, 0% 0%, 100% 0%, 100% 50%);
  }
  80.65% {
    clip-path: polygon(50% 50%, 0% 0%, 100% 0%, 100% 50%);
  }
  83.87% {
    clip-path: polygon(50% 50%, 25% 0%, 100% 0%, 100% 50%);
  }
  87.1% {
    clip-path: polygon(50% 50%, 50% 0%, 100% 0%, 100% 50%);
  }
  90.32% {
    clip-path: polygon(50% 50%, 75% 0%, 100% 0%, 100% 50%);
  }
  93.55% {
    clip-path: polygon(50% 50%, 100% 0%, 100% 50%);
  }
  96.77% {
    clip-path: polygon(50% 50%, 100% 25%, 100% 50%);
  }
  100% {
    clip-path: polygon(50% 50.1%, 100% 50.1%, 100% 50.1%);

  }
}
